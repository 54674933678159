import * as React from 'react';
import './App.css';
import CircleIcon from './components/CircleIcon';
import ShadedContent from './components/ShadedContent';
import EventLink from './components/EventLink';
import EventsLoadingState from './components/EventsLoadingState';
import SearchResultContainer from './components/SearchResultContainer';
import SearchResultMessage from './components/SearchResultMessage';
import * as helpers from './helpers';
import parse from "html-react-parser";

import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-loading-skeleton/dist/skeleton.css'
import { GAEventName, gaEvent } from './google-analytics';

const PAGE_SIZE = 20;
const DEFAULT_RESULT_HEIGHT = 100;

const MINIMUM_SEARCH_TERM_LENGTH = 3;

/**
 * Calculate the height of the results dropdown.  We want this floating below
 * the search bar down to just above the bottom of the screen
 */
const calculateResultHeight = () => {
    return helpers.getDistanceToBottom(document.getElementById('event-search-input')) - 20;
}

export default props => {

    const [hasSearched, setHasSearched]     = React.useState(false);
    const [currentPage, setCurrentPage]     = React.useState(1);
    const [totalPages, setTotalPages]       = React.useState(1);
    const [geoData, setGeoData]             = React.useState(null);
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [searchError, setSearchError]     = React.useState(false);
    const [debounceTimer, setDebounceTimer] = React.useState(null);
    const [heightTimer, setHeightTimer]     = React.useState(null);
    const [searchResults, setSearchResults] = React.useState([]);
    const [searchText, setSearchText]       = React.useState('');
    const [resultHeight, setResultHeight]   = React.useState(DEFAULT_RESULT_HEIGHT);

    // not sure why the casing is inconsistent on these, but handle it here
    const translations = {};
    Object.keys(props.translations).forEach((translationKey:string) => {
        translations[translationKey.toLowerCase()] = props.translations[translationKey];
    });


    /**
     * Get translation for stub, return react component(s)
     */
    const t = (stub) => {
        const translation = tString(stub);
        return translation ? parse(translation) : <span />;
    }

    /**
     * Get translation for stub, return a string
     */
    const tString = (stub): string => {
        return translations[stub] ? translations[stub] : '';
    }

    /**
     * Set the max height on the results container so that it is just above the bottom of the screen
     * Debounce this so that it isn't happening on every single event as that looks laggy
     */
    const updateResultHeight = () => {
        clearTimeout(heightTimer);
        setHeightTimer(setTimeout(() => {
            setResultHeight(calculateResultHeight());
        }, 30))
    }

    React.useEffect(() => {
        // request geo data on mount
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setGeoData(position);
            });
        }
        /**
         * Set the initial result height and bind events to update
         */
        updateResultHeight();
        window.addEventListener('scroll', () => {
            updateResultHeight();
        })
        window.addEventListener('resize', () => {
            updateResultHeight();
        })
    }, []);

    const fetchSearchResults = async (searchQuery: string, currentSearchResults: any[], page: number = 1) => {
        if (searchQuery) {
            setHasSearched(true);
            setSearchLoading(true);
            setSearchError(false);
            try {
                let url = `/api/upcoming-events/${encodeURIComponent(searchQuery)}?page=${page}&size=${PAGE_SIZE}`;
                if (geoData) {
                    url += `&lat=${geoData.coords.latitude}&lon=${geoData.coords.longitude}`;
                }
                const response = await fetch(url);
                const responseJson = await response.json();
                setSearchResults([...currentSearchResults, ...responseJson.documents]);
                if (responseJson.documents.length === 0) {
                    gaEvent(GAEventName.SearchEmpty, {
                        event_category: 'search',
                    })
                }

                setCurrentPage(responseJson.page);
                setTotalPages(Math.ceil(responseJson.total / PAGE_SIZE));
            } catch(err) {
                console.log(err);
                setSearchError(true);
            } finally {
              gaEvent(GAEventName.Search, {
                event_category: 'search',
              })
            }
            setSearchLoading(false);
        }
    };

    const handleSearchTextChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        const eventText = changeEvent.target.value;
        if (eventText.length < MINIMUM_SEARCH_TERM_LENGTH) {
          return;
        }

        setSearchText(eventText);
        clearTimeout(debounceTimer);
        setDebounceTimer(setTimeout(() => {
            setCurrentPage(1);
            setTotalPages(1);
            setSearchResults([]);
            fetchSearchResults(eventText, [], 1)
        }, 500))
    };

    const handleLoadOnScroll = ()  => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        fetchSearchResults(searchText, searchResults, nextPage);
    }

    return (
        <div className="page-wrap theme--tl">
            <div id="wrap">
                <div className="centered">
                    <main>

                        <div id="top-logo" />

                        <div id="modal">
                            <h1 id="modal-header">{t('eventaggregation__sign_in_to_my_tickets')}</h1>
                            <p>{t('eventaggregation__get_access_to_my_tickets')}</p>
                            <ShadedContent iconClass="icon-ticket">
                                <p>{t('eventaggregation__check_your_email')}</p>
                            </ShadedContent>
                            <div className="or-divider">
                                <span className="or-divider-text">{t('eventaggregation__or')}</span>
                            </div>
                            <ShadedContent iconClass="icon-search">
                                <p>{t('eventaggregation__search_for_event')}</p>
                                <div>
                                    <input
                                        id="event-search-input"
                                        onChange={handleSearchTextChange}
                                        type="text"
                                        placeholder={tString('eventaggregation__search_events')}
                                    />
                                </div>
                            </ShadedContent>
                            {!searchLoading && !!hasSearched && !searchResults.length && !searchError && !!searchText && (
                                <SearchResultContainer>
                                    <SearchResultMessage
                                        title={tString('eventaggregation__no_events_found')}
                                        message={tString('eventaggregation__check_your_spelling')}
                                    />
                                </SearchResultContainer>
                            )}
                            {!!searchError && (
                                <SearchResultContainer>
                                    <SearchResultMessage
                                        message={tString('eventaggregation__something_went_wrong')}
                                    />
                                </SearchResultContainer>
                            )}
                            {(!!searchResults.length || !!searchLoading) && !searchError && <SearchResultContainer height={`${resultHeight}px`}>
                                    <InfiniteScroll
                                        dataLength={searchResults.length}
                                        loader={<EventsLoadingState />}
                                        hasMore={currentPage < totalPages}
                                        next={handleLoadOnScroll}
                                        scrollableTarget="scroll-container"
                                    >
                                        {searchResults.map((searchResult: any, index) => {
                                            return (
                                                <EventLink
                                                    key={index}
                                                    loginUrl={searchResult.login_url}
                                                    eventName={searchResult.event_name}
                                                    eventImage={searchResult?.event_image ?? ''}
                                                    date={helpers.formatEventDate(props.locale, searchResult.event_start, searchResult?.event_end, searchResult?.event_timezone)}
                                                    location={helpers.formatLocation(searchResult?.venue_city, searchResult?.venue_state)}
                                                    seriesMessage={searchResult?.is_series ? tString('eventaggregation__multiple_dates_and_times') : ''}
                                                />
                                           );
                                        })}
                                        {!!searchLoading && !searchResults.length && <EventsLoadingState />}
                                    </InfiniteScroll>
                            </SearchResultContainer>
                            }
                            <div id="questions">
                                <a href={props.helpUrl} target="_blank" className="password-help">{t('eventaggregation__need_help')}</a>
                            </div>
                        </div>

                    </main>
                </div>
            </div>
            <footer id="page__footer">
                <div className="page__footer__top">
                    <ul className="page__footer__links">
                        <li><a href={props.tosUrl} target="_blank">{t('eventaggregation__terms_of_service')}</a></li>
                        <li><a href={props.privacyUrl} target="_blank">{t('eventaggregation__privacy_policy')}</a></li>
                        <li><a href={props.personalInfoUrl} target="_blank">{t('eventaggregation__dont_share_info')}</a></li>
                    </ul>
                </div>
                <div className="page__footer__bottom">
                    <p>© {new Date().getFullYear()} TicketLeap</p>
                </div>
            </footer>
        </div>
    );
}
