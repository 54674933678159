import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

export default props => {

    const imgSrc = props.eventImage
        ? props.eventImage
        : 'https://s3.amazonaws.com/static.leapevent.tech/design-system/design-system/v1.0.12/dist/images/event-image-empty.png';

    const content = (
        <div className={`event-link-container ${props.loading ? 'event-link-container-loading' : ''}`}>
            <div className="event-link-image-container">
                {!props.loading && <img className="event-link-image" src={imgSrc} />}
                {!!props.loading && <div className="event-link-image-loading"><Skeleton style={{height: '58px'}} /></div>}
            </div>
            <div className="event-link-content">
                {!props.loading && <React.Fragment>
                    {!!props.eventName && <div className="event-link-event-name text-lg-bold">{props.eventName}</div>}
                    {!!props.seriesMessage && <div className="event-link-date">{props.seriesMessage}</div>}
                    {!!props.date && !props.seriesMessage && <div className="event-link-date">{props.date}</div>}
                    {!!props.location && <div className="event-link-location">{props.location}</div>}
                </React.Fragment>}
                {!!props.loading && <React.Fragment>
                    <div className="event-link-event-name-loading"><Skeleton /></div>
                    <div className="event-link-date-loading"><Skeleton /></div>
                    <div className="event-link-location-loading"><Skeleton /></div>
                </React.Fragment>}
            </div>
        </div>
    );

    if (!!props.loading) {
        return content;
    }

    return (
        <a className="event-link-a-container" href={props.loginUrl} target="_blank">
            {content}
        </a>
    );
}
